//#region IMPORTS
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  Store,
  StoreState,
  StoreParams,
  StoreRequest,
  StoreFilters,
} from "../../models/store";
import { RootState } from "../../store/configureStore";
import { toast } from "react-toastify";
import getAxiosParams from "../../helpers/getAxiosParams";
import agent from "../../api/agent";
//#endregion

//#region INIT
// Set initial state
const initialExtraState: StoreState = {
  filters_loaded: false,
  stores_loaded: false,
  store_params: initParams(),
  metaData: null,
  filters: initFilters(),
  loading: false,
  status: "idle",
};

// Set initial query parameters
function initParams(): StoreParams {
  return {
    page: 1,
    limit: 25,
    active: "",
    sort: "",
    store_name: "",
    external_store_id: "",
    city: "",
    country_name: "",
    postal_code: "",
    language: "",
  };
}

// Set all filter options
function initFilters(): StoreFilters {
  return {
    language: [],
    country_name: [],
    city: [],
  };
}
//#endregion

//#region CREATE ADPATER
const storesAdapter = createEntityAdapter<Store>();
//#endregion

//#region READ
// Fetch All
export const fetchStoresAsync = createAsyncThunk<
  Store[],
  void,
  { state: RootState }
>("stores/fetchShopsAsync", async (_, thunkAPI) => {
  const storeParams = getAxiosParams(thunkAPI.getState().stores.store_params);
  try {
    const response = await agent.StoreManagement.list(storeParams);
    thunkAPI.dispatch(setStoresMetaData(response.meta));
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});

// Fetch all filters
export const fetchFilters = createAsyncThunk(
  "stores/fetchFiltersAsync",
  async (_, thunkAPI) => {
    try {
      return agent.StoreManagement.fetchFilters();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);
//#endregion

//#region UPDATE
export const updateStoreAsync = createAsyncThunk<
  Store,
  { storeId: number; values: StoreRequest }
>("stores/updateStoreAsync", async ({ storeId, values }, thunkAPI) => {
  try {
    const { data } = await agent.StoreManagement.update(storeId, { ...values });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.data });
  }
});
//#endregion

//#region CREATE REDUCER SLICE
export const storesSlice = createSlice({
  name: "stores",
  initialState: storesAdapter.getInitialState<StoreState>(initialExtraState),
  reducers: {
    setStoreParams: (state, action) => {
      state.stores_loaded = false;
      state.store_params = {
        ...state.store_params,
        ...action.payload,
        page: 1,
      };
    },
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    resetFilters: (state) => {
      state.filters = initFilters();
    },
    setStoresPageNumber: (state, action) => {
      state.stores_loaded = false;
      state.loading = true;
      state.store_params = { ...state.store_params, ...action.payload };
    },
    setStoresMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    resetStoreParams: (state) => {
      state.stores_loaded = false;
      state.store_params = initParams();
    },
    clearStores: () =>
      storesAdapter.getInitialState<StoreState>(initialExtraState),
    setStore: (state, action) => storesAdapter.upsertOne(state, action.payload),
    removeStore: (state, action) =>
      storesAdapter.removeOne(state, action.payload),
  },
  extraReducers: (builder) => {
    // FETCH stores
    builder.addCase(fetchStoresAsync.pending, (state) => {
      state.status = "pendingFetchShops";
      state.loading = true;
    });
    builder.addCase(fetchStoresAsync.fulfilled, (state, action) => {
      storesAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.stores_loaded = true;
      state.loading = false;
    });
    builder.addCase(fetchStoresAsync.rejected, (state) => {
      state.status = "idle";
      state.loading = false;
    });

    // FETCH filters
    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "pendingFetchFilters";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filters.city = action.payload.cities ?? [];
      state.filters.language = action.payload.languages ?? [];
      state.filters.country_name = action.payload.countries.map(
        (country: { name: string }) => country.name
      );
      state.filters_loaded = true;
      state.status = "idle";
    });
    builder.addCase(fetchFilters.rejected, (state) => {
      state.status = "idle";
    });

    // UPDATE store
    builder.addCase(updateStoreAsync.pending, (state, action) => {
      state.status = "PendingUpdateStore" + action.meta.arg.storeId;
    });
    builder.addCase(updateStoreAsync.fulfilled, (state, action) => {
      storesAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });
    builder.addCase(updateStoreAsync.rejected, (state) => {
      state.status = "idle";
      toast.error("Something went realy wrong");
    });
  },
});
//#endregion

//#region NORMALISED STATE
export const storeSelectors = storesAdapter.getSelectors(
  (state: RootState) => state.stores
);
//#endregion

//#region EXPORT
export const {
  setStoresPageNumber,
  setStoresMetaData,
  resetStoreParams,
  setStoreParams,
  updateFilters,
  resetFilters,
  removeStore,
  clearStores,
  setStore,
} = storesSlice.actions;
//#endregion
