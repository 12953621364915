//#region IMPORTS
import {
  fetchUsersAsync,
  resetUserParams,
} from "../../features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { FieldValues, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { User, UserRole } from "../../models/user";
import { toast } from "react-toastify";
import InputFieldErrorMark from "../errors/InputFieldErrorMark";
import SubmitButton from "../genericButtons/submitButton/SubmitButton";
import agent from "../../api/agent";
//#endregion

//#region INTERFACE
interface Props {
  cancelCUD: () => void;
  isAdmin: boolean;
  user?: User;
}
//#endregion

export default function UserForm({ cancelCUD, user, isAdmin }: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  // Local State
  const [iAmAware, setIamAware] = useState<boolean>(false);
  const { userRoles, userRoles_loaded } = useAppSelector(
    (state) => state.users
  );

  // UseForm
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Check if there is a user selected
  useEffect(() => {
    if (user) reset(user);
  }, [user, reset]);

  // Submit form
  async function submitForm(data: FieldValues) {
    // Validate store
    const params = new URLSearchParams();
    const sendData = { ...data };
    params.append("external_store_id", data.store.external_store_id);
    if (
      data.store.external_store_id !== "" &&
      data.store.external_store_id !== null
    ) {
      console.log(data.store.external_store_id);
      const storeResponse = await agent.StoreManagement.list(params);
      if (storeResponse.data.length <= 0) {
        toast.error("store not found");
        return;
      } else {
        sendData.store_id = storeResponse.data[0].id;
      }
    } else {
      sendData.store = null;
      sendData.store_id = null;
    }

    // Compare role to store
    if (sendData.store_id === null && sendData.function === "Member") {
      toast.error("Members need a store");
      return;
    }

    // Save the new or updated user
    try {
      if (user) {
        await agent.Users.updateUser(user.id, sendData);
        dispatch(resetUserParams());
      } else {
        await agent.Users.registerUser(sendData).then(() => {
          toast.success(`${sendData.username} added`);
        });
      }
      dispatch(fetchUsersAsync());
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  //#region RENDER
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="space-y-12">
        <div className="pb-12">
          {/* Header */}
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {user && !isAdmin
              ? "User details"
              : user && isAdmin
              ? "Update user"
              : "Create user"}
          </h2>

          {/* Form body */}
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {/* Username */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="username"
                className={
                  errors.username
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.username ? errors?.username?.message : "username"}
              </label>
              <div className="relative mt-2">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  placeholder="e.g. Jane Doe"
                  disabled={!isAdmin}
                  type="text"
                  id="username"
                  {...register("username", {
                    required: "Username is required",
                    minLength: {
                      value: 4,
                      message: "at least 4 characters please",
                    },
                  })}
                />
                <InputFieldErrorMark show={errors.username} />
              </div>
            </fieldset>

            {!user && (
              // Password
              <fieldset className="sm:col-span-3">
                <label
                  htmlFor="password"
                  className={
                    errors.password
                      ? "block text-sm font-medium leading-6 text-red-900"
                      : "block text-sm font-medium leading-6 text-gray-900"
                  }
                >
                  {errors.password ? errors?.password?.message : "password"}
                </label>
                <div className="relative mt-2">
                  <input
                    placeholder="e.g. MOTH"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                    disabled={!isAdmin}
                    type="password"
                    id="password"
                    {...register("password", {
                      required: "password is required",
                      minLength: {
                        value: 8,
                        message: "at least 8 characters please",
                      },
                    })}
                  />
                  <InputFieldErrorMark show={errors.password} />
                </div>
              </fieldset>
            )}

            {/* Email */}
            <fieldset className="sm:col-span-6">
              <label
                htmlFor="email"
                className={
                  errors.email
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.email ? errors?.email?.message : "email address"}
              </label>
              <div className="relative mt-2">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="email"
                  id="email"
                  placeholder="e.g. jane.doe@rituals.com"
                  {...register("email", {
                    required: "email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "entered value does not match email format",
                    },
                  })}
                />
                <InputFieldErrorMark show={errors.email} />
              </div>
            </fieldset>

            {/* Store id */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="externalStoreId"
                className={
                  errors.external_store_id
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.external_store_id
                  ? errors?.external_store_id?.message
                  : "store id"}
              </label>
              <div className="relative mt-2">
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  disabled={!isAdmin}
                  type="text"
                  id="externalStoreId"
                  placeholder="e.g. 1011"
                  {...register("store.external_store_id")}
                />
              </div>
            </fieldset>

            {/* Function */}
            <fieldset className="sm:col-span-3">
              <label
                htmlFor="function"
                className={
                  errors.function
                    ? "block text-sm font-medium leading-6 text-red-900"
                    : "block text-sm font-medium leading-6 text-gray-900"
                }
              >
                {errors.function ? errors?.function?.message : "user Role"}
              </label>
              <div className="relative mt-2">
                <select
                  id="function"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                  {...register("function")}
                  defaultValue="Member"
                >
                  {userRoles_loaded &&
                    userRoles?.map((role: UserRole) => (
                      <option key={role.name} value={role.name}>
                        {role.name}
                      </option>
                    ))}
                </select>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      {/* Actions */}
      {isAdmin && (
        <div className="mt-6 flex items-center justify-between gap-x-6">
          <div className="flex items-center gap-x-6">
            <input
              className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
              type="checkbox"
              autoFocus={true}
              checked={iAmAware}
              onChange={() => setIamAware((prev) => !prev)}
            />
            <label className="font-medium text-gray-900">
              I am aware this changes real data
            </label>
          </div>
          <div className="flex items-center gap-x-6">
            <button
              onClick={cancelCUD}
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <SubmitButton
              conditionals={[isValid, iAmAware]}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      )}
    </form>
  );
  //#endregion
}
