import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { translationManagerSlice } from "../features/translationManager/translatioManagerSlice";
import { productVersionsSlice } from "../features/productVersions/productVersionsSlice";
import { basketReasonSlice } from "../features/basketReason/basketReasonSlice";
import { storeFormatSlice } from "../features/storeFormats/storeFormatSlice";
import { dashboardSlice } from "../features/dashBoard/dashboardSlice";
import { backOrderSlice } from "../features/backorder/backOrderSlice";
import { configureStore } from "@reduxjs/toolkit";
import { productsSlice } from "../features/products/productsSlice";
import { accountSlice } from "../features/accounts/accountSlice";
import { ordersSlice } from "../features/orders/ordersSlice";
import { basketSlice } from "../features/basket/basketSlice";
import { storesSlice } from "../features/stores/storesSlice";
import { promoSlice } from "../features/promos/promosSlice";
import { usersSlice } from "../features/users/usersSlice";

export const store = configureStore({
  reducer: {
    translationManagement: translationManagerSlice.reducer,
    productVersions: productVersionsSlice.reducer,
    backOrderItems: backOrderSlice.reducer,
    basketReasons: basketReasonSlice.reducer,
    storeFormats: storeFormatSlice.reducer,
    dashboard: dashboardSlice.reducer,
    products: productsSlice.reducer,
    account: accountSlice.reducer,
    basket: basketSlice.reducer,
    stores: storesSlice.reducer,
    orders: ordersSlice.reducer,
    promos: promoSlice.reducer,
    users: usersSlice.reducer,
  },
});

// GLOBAL REDUX TYPES
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// GLOBAL REDUX TYPED custom hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
