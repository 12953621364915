//#region IMPORTS
import { setStoreFormatsPageNumber } from "../../features/storeFormats/storeFormatSlice";
import { useAppDispatch } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { StoreFormat } from "../../models/storeFormat";
import { MetaData } from "../../models/pagination";
import MainTableMotionWrapper from "../_layout/MainTableMotionWrapper";
import StoreFormatListItem from "./StoreFormatListItem";
import LoadingOverlay from "../loading/LoadingOverlay";
import PaginationBar from "../paginationBar/PaginationBar";
//#endregion

//#region INTERFACE
interface Props {
  storeFormatsLoaded: boolean;
  metaData: MetaData | null;
  storeFormats: StoreFormat[];
}
//#endregion

export default function StoreFormatList({
  storeFormatsLoaded,
  metaData,
  storeFormats,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();

  //#endregion

  //#region FAILSAFE
  if (storeFormatsLoaded && storeFormats.length <= 0)
    return <h2>{t("rituals.notFound")}</h2>;
  //#endregion

  //#region RENDER
  return (
    <div className="relative">
      {!storeFormatsLoaded && <LoadingOverlay />}
      <MainTableMotionWrapper>
        <ul className="divide-y divide-gray-100">
          {storeFormats.map((storeFormat) => (
            <StoreFormatListItem
              storeFormat={storeFormat}
              key={storeFormat.id}
            />
          ))}
        </ul>
      </MainTableMotionWrapper>
      <footer>
        {metaData && metaData?.total > 0 && (
          <PaginationBar
            metaData={metaData}
            onPageChange={(page: number) =>
              dispatch(setStoreFormatsPageNumber({ page }))
            }
            pageType={t("rituals.storeFormats")}
          />
        )}
      </footer>
    </div>
  );
  //#endregion
}
