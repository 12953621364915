//#region IMPORTS
import {
  fetchFilters,
  storeSelectors,
  setStoreParams,
  fetchStoresAsync,
  updateStoreAsync,
} from "./storesSlice";
import {
  CHANGE_STORE_COMMENT,
  UPLOAD_DATA_FOR_STORE,
  // CREATE_OR_UPDATE_STORE,
  CREATE_OR_UPDATE_STOREFORMAT,
} from "../../models/_consts";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { closeFilterBar, openModal } from "../dashBoard/dashboardSlice";
import { AnimatePresence, motion } from "framer-motion";
import { Store, StoreParams } from "../../models/store";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import { useTranslation } from "react-i18next";
import { EyeIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import StoresCustomFilters from "../../components/stores/StoresCustomFilters";
import StoreFormatContainer from "../../components/storeFormats/StoreFormatContainer";
import MainMotionSection from "../../components/_layout/MainMotionSection";
import StoresDataUpload from "../../components/stores/StoresDataUpload";
import useAdminManager from "../../hooks/useAdminManager";
import AddYourComment from "../../components/modalTypes/AddYourComment";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import useFilterCount from "../../hooks/useFilterCount";
import MainContainer from "../../components/_layout/MainContainer";
import StoresTable from "../../components/stores/StoresTable";
import CancelIcon from "../../components/icons/CancelIcon";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import StoreForm from "../../components/stores/StoreForm";
import MainModal from "../../components/_layout/MainModal";
import FilterBar from "../../components/filterBar/FilterBar";
import agent from "../../api/agent";
import "./StoresPage.css";
//#endregion

export default function StoresPage() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Redux
  const dispatch = useAppDispatch();
  const stores = useAppSelector(storeSelectors.selectAll);
  const { user } = useAppSelector((state) => state.account);
  const { stores_loaded, filters_loaded, metaData } = useAppSelector(
    (state) => state.stores
  );

  // Local State
  const [storeToChangeComment, setStoreToChangeComment] =
    useState<Store | null>(null);
  const [savingDataUpload, setSavingDataUpload] = useState<boolean>(false);
  const [savingComment, setSavingComment] = useState<boolean>(false);
  const [uploadMode, setUploadMode] = useState<boolean>(false);
  const [create, setCreate] = useState<boolean>(false);
  const [createFormat, setCreateFormat] = useState<boolean>(false);
  const isAdmin = useAdminManager(user);

  // Set Filters
  const { page, limit, sort, ...selectedFilters }: StoreParams = useAppSelector(
    (state) => state.stores.store_params
  );
  const filterCount = useFilterCount(selectedFilters);
  //#endregion

  //#region LOGIC
  // Load filters
  useEffect(() => {
    if (!filters_loaded) dispatch(fetchFilters());
  }, [filters_loaded, dispatch]);

  // Load stores
  useEffect(() => {
    !stores_loaded && dispatch(fetchStoresAsync());
  }, [stores_loaded, dispatch]);

  // Scrollblock
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    if (uploadMode) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [uploadMode, blockScroll, allowScroll]);

  // Close filterbar onleave
  useEffect(() => {
    return () => {
      dispatch(closeFilterBar());
    };
  }, [dispatch]);

  // Create new store
  const toggleCreateStore = () => {
    if (uploadMode) {
      dispatch(openModal(false));
      setUploadMode(false);
    }
    if (create) {
      // dispatch(openModal(false));
      setCreate(false);
    } else {
      // dispatch(dispatch(openModal(CREATE_OR_UPDATE_STORE)));
      setCreate(true);
    }
  };

  // Create new store format
  const toggleCreateStoreFormat = () => {
    if (createFormat) {
      dispatch(openModal(false));
      setCreateFormat(false);
    } else {
      dispatch(dispatch(openModal(CREATE_OR_UPDATE_STOREFORMAT)));
      setCreateFormat(true);
    }
  };

  // Upload stores
  const toggleUploadStores = () => {
    if (create) {
      setCreate(false);
    }
    if (uploadMode) {
      dispatch(openModal(false));
      setUploadMode(false);
    } else {
      dispatch(dispatch(openModal(UPLOAD_DATA_FOR_STORE)));
      setUploadMode(true);
    }
  };

  // Set comment to change
  const changeCommentHandler = (store: Store) => {
    setStoreToChangeComment(store);
    dispatch(openModal(CHANGE_STORE_COMMENT));
  };

  // Update store with new comment
  const updateStoreCommentHandler = async (newComment: string) => {
    setSavingComment(true);
    if (storeToChangeComment) {
      const { data } = await agent.StoreManagement.detail(
        storeToChangeComment.id
      );
      data.comment = newComment;
      if (data.same_as_address) {
        delete data.delivery_addresses;
      }
      await dispatch(
        updateStoreAsync({ storeId: storeToChangeComment.id, values: data })
      );
      toast.success(
        `The comment ${data.store_name} has been succesfully changed.`
      );
    }
    cancelOrEndChangeComment();
  };

  // Cancel or end changing an comment
  const cancelOrEndChangeComment = () => {
    dispatch(openModal(false));
    setStoreToChangeComment(null);
    setSavingComment(false);
  };

  // Download all stores in a spreadsheet
  const downloadStoresSpreadsheet = async () => {
    try {
      await agent.StoreManagement.downloadAllAsSpreadsheet().then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "current-stores.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      );
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  //#endregion

  //#region FAIL SAFE
  if (!stores_loaded && !filters_loaded) return <LoadingSpinner />;
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* MODALS */}
      <MainModal
        type={CHANGE_STORE_COMMENT}
        closeHandler={cancelOrEndChangeComment}
      >
        <AddYourComment
          isSaving={savingComment}
          defaultValue={storeToChangeComment?.comment}
          saveComment={(comment: string) => updateStoreCommentHandler(comment)}
        />
      </MainModal>

      <MainModal type={UPLOAD_DATA_FOR_STORE} closeHandler={toggleUploadStores}>
        <StoresDataUpload
          setSavingHandler={(value: boolean) => setSavingDataUpload(value)}
          cancelCUD={toggleUploadStores}
          saving={savingDataUpload}
          customer_id="1"
        />
      </MainModal>

      {/* COMING SOON */}
      {/* <MainModal type={CREATE_OR_UPDATE_STORE} closeHandler={toggleCreateStore}>
        TEST
      </MainModal> */}

      <MainModal
        type={CREATE_OR_UPDATE_STOREFORMAT}
        closeHandler={toggleCreateStoreFormat}
      >
        <StoreFormatContainer />
      </MainModal>

      <MainContainer>
        <FilterBar
          selectedFilters={selectedFilters}
          setParamsHandler={(values: any) => {
            dispatch(setStoreParams(values));
          }}
          directTo="/stores"
        >
          <StoresCustomFilters />
        </FilterBar>

        <MainMotionSection>
          <PageHeader
            title={t("rituals.storeManager")}
            createButtonValue={create ? t("rituals.save") : t("rituals.add")}
            uploadClickHandler={toggleUploadStores}
            downloadClickHandler={downloadStoresSpreadsheet}
            createClickHandler={toggleCreateStore}
            uploadButtonValue={t("rituals.uploadExcel")}
            filterCount={filterCount}
            isAdmin={isAdmin}
            create={create}
            download={true}
            filter={true}
            extraButtons={
              <button
                onClick={toggleCreateStoreFormat}
                type="button"
                className="inline-flex items-center gap-x-2 px-3.5 py-3 text-1xl font-semibold text-slate-600 hover:text-slate-400"
              >
                <EyeIcon className="-ml-0.5 h-8 w-8" aria-hidden="true" />
                {t("rituals.storeFormats")}
              </button>
            }
          />

          {/* Create single new store */}
          {create ? (
            <AnimatePresence>
              <motion.div
                className="store-page__content__new-box"
                initial={{ x: 200, opacity: 1 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 200, opacity: 0 }}
              >
                {/* CancelButton */}
                <motion.button
                  whileHover={{ scale: 1.1, rotate: "90deg" }}
                  className="store-page__content__new-box__cancel"
                  onClick={toggleCreateStore}
                >
                  <CancelIcon />
                </motion.button>
                <StoreForm cancelCUD={toggleCreateStore} create={create} />
              </motion.div>
            </AnimatePresence>
          ) : (
            <StoresTable
              commentHandler={(store: Store) => changeCommentHandler(store)}
              filtersLoaded={filters_loaded}
              storesLoaded={stores_loaded}
              metaData={metaData}
              stores={stores}
            />
          )}
        </MainMotionSection>
      </MainContainer>
    </Fragment>
  );
  //#endregion
}
