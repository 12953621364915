import "./CancelButton.css";
import CancelIcon from "../../icons/CancelIcon";

interface Props {
  clickHandler?: () => void;
  color?: string;
  value?: string;
  shake?: boolean;
  className?: string;
}

function CancelButton({
  clickHandler,
  color,
  value,
  shake = false,
  className,
}: Props) {
  return (
    <button
      onClick={clickHandler}
      className={`${className ? className + " " : ""}cancel-button${
        shake ? " cancel-button--shake" : ""
      }`}
    >
      <CancelIcon color={color} />
      <p className="cancel-button__value" style={{ color: color }}>
        {value}
      </p>
    </button>
  );
}

export default CancelButton;
