//#region IMPORTS
import {
  setStoreParams,
  resetStoreParams,
} from "../../features/stores/storesSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useTranslation } from "react-i18next";
import { StoreParams } from "../../models/store";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import FilterBarComboBox from "../filterBar/FilterBarComboBox";
import FilterBarToggle from "../filterBar/FilterBarToggle";
import FilterBarSearch from "../filterBar/FilterBarSearch";
import useFilterCount from "../../hooks/useFilterCount";
//#endregion

export default function StoresCustomFilters() {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { stores_loaded, filters, store_params } = useAppSelector(
    (state) => state.stores
  );

  // Set Filters
  const { page, limit, sort, ...selectedFilters }: StoreParams = useAppSelector(
    (state) => state.stores.store_params
  );
  const filterCount = useFilterCount(selectedFilters);
  //#endregion

  //#region LOGIC
  // Show only active stores
  const toggleActiveStoresHandler = () => {
    store_params.active === "Yes"
      ? dispatch(setStoreParams({ active: "" }))
      : dispatch(setStoreParams({ active: "Yes" }));
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      {/* Search */}
      <h2 className="ml-8 font-semibold text-amber-600 text-2xl">
        {t("rituals.search")}
      </h2>
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ store_name: values }))
        }
        title={t("rituals.storeName")}
      />
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ external_store_id: values }))
        }
        title={t("rituals.storeId")}
      />
      <FilterBarSearch
        setSearchParamHandler={(values) =>
          dispatch(setStoreParams({ postal_code: values }))
        }
        title={t("rituals.postalCode")}
      />
      <FilterBarToggle
        value={store_params.active === "Yes" ? true : false}
        refresh={stores_loaded}
        toggleHandler={toggleActiveStoresHandler}
        title={"show only active stores"}
      />

      {/* Filters */}
      <div className="flex justify-between">
        <h2 className="ml-8 mb-4 mt-8 font-semibold text-amber-600 text-2xl">
          {t("rituals.filters")}
        </h2>
        {filterCount > 0 && (
          <button
            onClick={() => {
              dispatch(resetStoreParams());
              history.push("/stores");
            }}
            className="filterbar__content__section__header__clearbutton"
          >
            {t("rituals.clearAll")}
          </button>
        )}
      </div>

      {/* Country */}
      {filters.country_name.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ country_name: value }))
          }
          items={filters.country_name}
          title={t("rituals.countryName")}
          selectedValue={selectedFilters.country_name ?? ""}
        />
      )}

      {/* Language */}
      {filters.language.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ language: value }))
          }
          items={filters.language}
          title={t("rituals.language")}
          selectedValue={selectedFilters.language ?? ""}
        />
      )}

      {/* City */}
      {filters.city.length > 0 && (
        <FilterBarComboBox
          setSearchParamHandler={(value) =>
            dispatch(setStoreParams({ city: value }))
          }
          items={filters.city}
          title={t("rituals.city")}
          selectedValue={selectedFilters.city ?? ""}
        />
      )}
    </Fragment>
  );
  //#endregion
}
