//#region IMPORTS
import { fetchUserRolesAsync, removeUser } from "./usersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { User } from "../../models/user";
import UserPasswordResetForm from "../../components/users/UserPasswordResetForm";
import UserRegisterForm from "../../components/users/UsersRegisterForm";
import CheckoutButton from "../../components/genericButtons/checkoutButton/CheckoutButton";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import DeleteButton from "../../components/genericButtons/deleteButton/DeleteButton";
import CancelButton from "../../components/genericButtons/cancelButton/CancelButton";
import GoBackButton from "../../components/genericButtons/goBackButton/GoBackButton";
import PageHeader from "../../components/genericPageHeader/PageHeader";
import agent from "../../api/agent";
//#endregion

export default function UsersDetailPage() {
  //#region SETUP
  // Router
  const history = useHistory();

  // Redux
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { status } = useAppSelector((state) => state.users);
  const { user: AppUser } = useAppSelector((state) => state.account);
  const { userRoles_loaded } = useAppSelector((state) => state.users);

  // Local state
  const [userDetail, setUserDetail] = useState<User>();
  const [resetPasswordMode, setResetPasswordMode] = useState<boolean>(false);
  //#endregion

  //#region LOGIC
  useEffect(() => {
    const fetchUserDetail = async () => {
      if (!userDetail) {
        const { data } = await agent.Users.detail(Number(id));
        setUserDetail(data);
      }
    };
    fetchUserDetail();
  }, [id, userDetail, dispatch]);

  // Load user roles
  useEffect(() => {
    if (!userRoles_loaded) {
      dispatch(fetchUserRolesAsync());
    }
  }, [userRoles_loaded, dispatch]);

  // Set resetPasswordMode
  const resetPasswordModeHandler = () => {
    setResetPasswordMode(true);
  };
  const cancelPasswordModeHandler = () => {
    setResetPasswordMode(false);
  };

  const deleteHandler = async () => {
    try {
      if (userDetail) {
        await agent.Users.delete(userDetail.id).then(() => {
          history.push("/users");
          toast.success(`${userDetail.username} deleted`);
          dispatch(removeUser(userDetail.id));
        });
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  //#endregion

  //#region FAIL SAFE
  if (status.includes("pending")) return <LoadingSpinner />;

  //#endregion

  //#region RENDER
  return (
    <article>
      <PageHeader
        title="User detail"
        extra={
          resetPasswordMode ? (
            <CancelButton
              value="Cancel"
              color="red"
              clickHandler={cancelPasswordModeHandler}
            />
          ) : (
            <CheckoutButton
              showCrossIcon={false}
              color="#254559"
              value="Change password?"
              clickHandler={resetPasswordModeHandler}
            />
          )
        }
      >
        <GoBackButton />
      </PageHeader>
      {userDetail &&
        (!resetPasswordMode ? (
          <UserRegisterForm
            user={userDetail}
            updateMode={true}
            admin={AppUser !== null && AppUser.roles.includes("Admin")}
            cancelCUD={() => {
              history.push("/users");
            }}
          />
        ) : (
          <UserPasswordResetForm
            user={userDetail}
            admin={AppUser !== null && AppUser.roles.includes("Admin")}
            cancelCUD={() => {
              history.push("/users");
            }}
          />
        ))}

      {AppUser &&
        userDetail &&
        AppUser.roles.includes("Admin") &&
        userDetail.function !== "Admin" &&
        !resetPasswordMode && (
          <DeleteButton color="#f25c5c" clickHandler={deleteHandler} />
        )}
    </article>
  );
  //#endregion
}
