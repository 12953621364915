//#region IMPORTS
import {
  storeFormatSelectors,
  fetchStoreFormatsAsync,
  createStoreFormatAsync,
} from "../../features/storeFormats/storeFormatSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import StoreFormatContainerHeader from "./StoreFormatContainerHeader";
import StoreFormatList from "./StoreFormatList";
//#endregion

export default function StoreFormatContainer() {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();
  const { storeFormats_loaded, metaData } = useAppSelector(
    (state) => state.storeFormats
  );
  const storeFormats = useAppSelector(storeFormatSelectors.selectAll);

  // Local state
  const [create, setCreate] = useState<boolean>(false);
  const [newFormatName, setNewFormatName] = useState<string>("");

  //#endregion

  //#region LOGIC
  // Load storeFormats
  useEffect(() => {
    !storeFormats_loaded && dispatch(fetchStoreFormatsAsync());
  }, [storeFormats_loaded, dispatch]);

  // Save new storeFormat
  const saveHandler = async () => {
    await dispatch(
      createStoreFormatAsync({
        values: {
          name: newFormatName,
          customer_id: 1,
        },
      })
    );
    setCreate(false);
  };
  //#endregion

  //#region RENDER
  return (
    <Fragment>
      <StoreFormatContainerHeader
        createMode={create}
        addHandler={() => setCreate(true)}
        saveHandler={saveHandler}
      />
      {create && (
        <input
          placeholder="e.g. premium store"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6 mb-4"
          onChange={(e: any) => setNewFormatName(e.target.value)}
          type="text"
        />
      )}

      <StoreFormatList
        storeFormats={storeFormats}
        storeFormatsLoaded={storeFormats_loaded}
        metaData={metaData}
      />
    </Fragment>
  );
  //#endregion
}
