//#region IMPORTS
import { resetUserParams, fetchUsersAsync } from "../../features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "../../store/configureStore";
import { Fragment, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { User, UserRole } from "../../models/user";
import { toast } from "react-toastify";
import LoadingSpinner from "../loading/LoadingSpinner";
import agent from "../../api/agent";
import "./UsersRegisterForm.css";
//#endregion

//#region INTERFACE
interface Props {
  updateMode?: boolean;
  cancelCUD: () => void;
  admin: boolean;
  user?: User;
}
//#endregion

export default function UserRegisterForm({
  updateMode = false,
  cancelCUD,
  admin = false,
  user,
}: Props) {
  //#region SETUP
  // Redux
  const dispatch = useAppDispatch();

  // Local State
  const [iAmAware, setIamAware] = useState<boolean>(false);
  const { userRoles, userRoles_loaded } = useAppSelector(
    (state) => state.users
  );

  // UseForm configuration
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Check if there is a user selected
  useEffect(() => {
    if (user) reset(user);
  }, [user, reset]);

  // OnSubmit
  async function submitForm(data: FieldValues) {
    // Validate store
    const params = new URLSearchParams();
    const sendData = { ...data };
    params.append("external_store_id", data.store.external_store_id);
    if (data.store.external_store_id !== "") {
      const storeResponse = await agent.StoreManagement.list(params);
      console.log(storeResponse);
      if (storeResponse.data.length <= 0) {
        toast.error("store not found");
        return;
      } else {
        sendData.store_id = storeResponse.data[0].id;
      }
    } else {
      sendData.store = null;
      sendData.store_id = null;
    }

    // Compare role to store
    if (sendData.store_id === null && sendData.function === "Member") {
      toast.error("Members need a store");
      return;
    }

    // Save the new or updated user
    try {
      if (user) {
        await agent.Users.updateUser(user.id, sendData);
        dispatch(resetUserParams());
      } else {
        await agent.Users.registerUser(sendData).then(() => {
          toast.success(`${sendData.username} added`);
        });
      }
      dispatch(fetchUsersAsync());
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  //#region RENDER
  // Initial loading spinner
  if (!userRoles_loaded && userRoles.length <= 0) return <LoadingSpinner />;

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`users-form${isValid ? " users-form--valid" : ""}`}
    >
      <div className="users-form__fields">
        {/* LEFT COLUMN */}
        <fieldset className="users-form__inputgroup">
          {/* USER NAME FIELD */}
          <label
            htmlFor="username"
            className="block text-sm font-medium leading-6 text-gray-900"
            style={{ color: errors.username ? "red" : "inherit" }}
          >
            {errors.username ? errors?.username?.message : "User name"}
          </label>
          <input
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="text"
            id="username"
            placeholder="e.g. Jane Doe"
            {...register("username", {
              required: "Username is required",
              minLength: { value: 4, message: "at least 4 characters please" },
            })}
          />

          {!updateMode && (
            <Fragment>
              {/* PASSWORD FIELD */}
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
                style={{ color: errors.username ? "red" : "inherit" }}
              >
                {errors.password ? errors?.password?.message : "User password"}
              </label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="password"
                id="password"
                placeholder="e.g. ls541741sdf144LK!"
                {...register("password", {
                  required: "password is required",
                  minLength: {
                    value: 3,
                    message: "at least 3 characters please",
                  },
                })}
              />
            </Fragment>
          )}

          {/* EMAIL FIELD */}
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
            style={{ color: errors.username ? "red" : "inherit" }}
          >
            {errors.email ? errors?.email?.message : "Email"}
          </label>
          <input
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="email"
            id="email"
            placeholder="e.g. jane.doe@rituals.com"
            {...register("email", {
              required: "email is required",
              minLength: { value: 2, message: "at least 2 characters please" },
            })}
          />
        </fieldset>

        <fieldset className="users-form__inputgroup">
          {/* RIGHT COLUMN */}
          {/* STORE ID FIELD */}
          <label
            htmlFor="externalStoreId"
            className="block text-sm font-medium leading-6 text-gray-900"
            style={{ color: errors.username ? "red" : "inherit" }}
          >
            {errors.external_store_id
              ? errors?.external_store_id?.message
              : "Store Number"}
          </label>
          <input
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="text"
            id="externalStoreId"
            placeholder="e.g. 1011"
            {...register("store.external_store_id")}
          />

          {/* USER ROLE FIELD */}
          <label
            htmlFor="function"
            className="block text-sm font-medium leading-6 text-gray-900"
            style={{ color: errors.username ? "red" : "inherit" }}
          >
            {errors.function ? errors?.function?.message : "User Role"}
          </label>

          <select
            id="function"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            {...register("function")}
            defaultValue="Member"
          >
            {userRoles_loaded &&
              userRoles?.map((role: UserRole) => (
                <option key={role.name} value={role.name}>
                  {role.name}
                </option>
              ))}
          </select>
        </fieldset>
      </div>

      {admin && (
        <div className="users-form__actions">
          <button
            onClick={cancelCUD}
            type="button"
            className="rounded-full bg-red-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Cancel
          </button>

          <input
            type="submit"
            value={isSubmitting ? "Saving..." : "Save"}
            disabled={!isValid || !iAmAware}
            className="rounded-full bg-emerald-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:opacity-20"
          />
          <input
            className="promo-form__inputgroup__inputfield--checkbox aware-of-trouble--checkbox"
            type="checkbox"
            checked={iAmAware}
            onChange={() => setIamAware((prev) => !prev)}
          />
          <label className="aware-of-trouble">
            I am aware this changes real data
          </label>
        </div>
      )}
      {/* SUBMIT ACTIONS */}
    </form>
  );
  //#endregion
}
