//#region IMPORTS
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//#endregion

//#region INTERFACE
interface Props {
  setSearchParamHandler: (values: any) => void;
  lastValue?: string;
  title: string;
}
//#endregion

export default function FilterBarSearch({
  setSearchParamHandler,
  lastValue,
  title,
}: Props) {
  //#region SETUP
  // Translation
  const { t } = useTranslation();

  // Local state
  const [search, setSearch] = useState("");
  //#endregion

  //#region LOGIC
  const clearSearhHandler = () => {
    setSearch("");
    setSearchParamHandler("");
  };

  useEffect(() => {
    lastValue && setSearch(lastValue);
  }, [lastValue]);

  const searchHandler = (e: any) => {
    e.preventDefault();
    setSearchParamHandler(search);
  };
  //#endregion

  //#region RENDER
  return (
    <section className="filterbar__content__section">
      <header className="filterbar__content__section__header">
        <h2 className="font-semibold text-gray-900">{title}</h2>
        {search && (
          <button
            onClick={clearSearhHandler}
            className="filterbar__content__section__header__clearbutton"
          >
            {t("rituals.clearAll")}
          </button>
        )}
      </header>
      <form
        onSubmit={searchHandler}
        className="mb-4 pl-8 sm:flex sm:items-center"
      >
        <input
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-500 sm:text-sm sm:leading-6"
          type="text"
          name={title}
          id={title}
          value={search || ""}
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
        />
        <button type="submit">
          <MagnifyingGlassIcon className="w-6 h-6 ml-2 text-gray-500 hover:text-gray-900" />
        </button>
      </form>
    </section>
  );
  //#endregion
}
