//#region IMPORTS
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { User } from "../../models/user";
import SaveButton from "../genericButtons/saveButton/SaveButton";
import agent from "../../api/agent";
import "./UsersRegisterForm.css";
//#endregion

//#region INTERFACE
interface Props {
  cancelCUD: () => void;
  admin: boolean;
  user: User;
}
//#endregion

export default function UserPasswordResetForm({
  cancelCUD,
  admin = false,
  user,
}: Props) {
  //#region SETUP
  // Local State
  const [iAmAware, setIamAware] = useState<boolean>(false);

  // UseForm configuration
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({ mode: "all" });
  //#endregion

  //#region LOGIC
  // Check if there is a user selected
  useEffect(() => {
    if (user) reset(user);
  }, [user, reset]);

  // OnSubmit
  async function submitForm(data: FieldValues) {
    try {
      // Change password enpoint call here
      await agent.Users.updatePassword(user?.id, data).then(() => {
        toast.success(`The password of ${data.username} was changed succesful`);
      });
      cancelCUD();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  //#endregion

  if (!admin) {
    cancelCUD();
  }

  //#region RENDER
  return admin ? (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`users-form${isValid ? " users-form--valid" : ""}`}
    >
      <div className="users-form__fields">
        {/* LEFT COLUMN */}
        <fieldset className="users-form__inputgroup">
          {/* PASSWORD FIELD */}
          <label
            className={
              errors.password ? "users-form__inputgroup__label--error" : ""
            }
          >
            {errors.password ? errors?.password?.message : "New password"}
          </label>
          <input
            className="users-form__inputgroup__inputfield"
            type="password"
            placeholder="e.g. ls541741sdf144LK!"
            {...register("password", {
              required: "password is required",
              minLength: {
                value: 3,
                message: "at least 3 characters please",
              },
            })}
          />

          {/* PASSWORD CONFIRM FIELD */}
          <label
            className={
              errors.confirm_password
                ? "users-form__inputgroup__label--error"
                : ""
            }
          >
            {errors.confirm_password
              ? errors?.confirm_password?.message
              : "Confirm new password"}
          </label>
          <input
            className="users-form__inputgroup__inputfield"
            type="password"
            placeholder="e.g. ls541741sdf144LK!"
            {...register("confirm_password", {
              required: "confirm_password is required",
              minLength: {
                value: 3,
                message: "at least 3 characters please",
              },
            })}
          />
        </fieldset>
      </div>

      <div className="users-form__actions">
        <SaveButton
          type="submit"
          value={isSubmitting ? "Saving..." : "Save"}
          valid={isValid && iAmAware}
        />
        <input
          className="promo-form__inputgroup__inputfield--checkbox aware-of-trouble--checkbox"
          type="checkbox"
          checked={iAmAware}
          onChange={() => setIamAware((prev) => !prev)}
        />
        <label className="aware-of-trouble">
          I am aware this changes the password of {user.username}
        </label>
      </div>
    </form>
  ) : null;
  //#endregion
}
